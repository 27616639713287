/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100vh;} */

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
input[type=file]::file-selector-button {
    
    padding: 5px 10px;
    border-radius: .3em;
    background-color: #3f51b5;
    color: #f5f5f5f5;
    transition: 1s;
    outline: none;
    border:none;

  }
  